import * as React from "react"
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';

// Import MAIN CSS
import './static/css/main.less'

// Import Jquery
import './node_modules/jquery/dist/jquery.min.js'

import './node_modules/lodash'

// Import MAIN JS
//import './static/js/main.js'
import './node_modules/@stripe/react-stripe-js/dist/react-stripe.js'

// Import Tailwind
import './node_modules/tailwindcss/base.css'
import './node_modules/tailwindcss/components.css'
import './node_modules/tailwindcss/utilities.css'

// Apollo
const httpLink = new HttpLink({
  uri: process.env.GATSBY_HYGRAPH_ENDPOINT,
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_HYGRAPH_TOKEN}`,
  },
  fetch: fetch,
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://upload-widget.cloudinary.com/global/all.js")
  }
}

export { wrapRootElement }